import styled from "styled-components";

export const DooblePawnContainer = styled.div`

display: none;

@media only screen and (min-width: 1440px) {
      display: block;
      width: 2.5rem;
    }

`;
